<template>
  <div>
    <CRow>
      <CCol col="12">
           <CCard class="cards">
          <form id="updateOrder" @submit="updateOrder" >
          <CCardHeader>
            <CCol col="6" class="styleHeader"> {{$t('message.updateOrder')}}</CCol>
          </CCardHeader>
          <CCardBody>
            <CRow>
                <CCol sm="6" class="m-auto orderstype">
                  {{ $t("message.orderType") }}
                  <span class="star">*</span>
                  <CInput
                    :label="$t('message.sales')"
                    class="sm"
                    type="radio"
                    name="orderType"
                    @change="selectOrder(1)"
                    :checked="selectedTypeOfOrder === 1"
                    value="1"
                    id="order-type"
                  />
                  <CInput
                    :label="$t('message.purchase')"
                    type="radio"
                    name="orderType"
                    @change="selectOrder(2)"
                    :checked="selectedTypeOfOrder === 2"
                    value="2"
                    id="order-type"
                  />
                </CCol>
              </CRow>
              <hr />
               <div v-if="selectedTypeOfOrder === 1">
                <CRow>
                  <h4>{{ $t("message.saleOrderNote") }}</h4>
                </CRow>
                <CRow>
                  <CCol sm="6">
                    <label for="suppliers">
                      {{ $t("message.office") }}
                      <span class="star">*</span>
                    </label>
                    <multiselect
                      class="required"
                      v-model="selectedOffice"
                      id="office-id"
                      :options="offices"
                      @select="onOfficeChange"
                      :searchable="true"
                      :close-on-select="true"
                      :placeholder="$t('message.select')"
                      :selectLabel="$t('message.pressSelect')"
                      label="name"
                      track-by="name"
                      required="required"
                    >
                      <span slot="noOptions">{{
                        $t("message.emptyList")
                      }}</span>
                      <span slot="noResult">{{ $t("message.noResult") }} </span>
                    </multiselect>
                  </CCol>
                </CRow>
                <CRow class="mt-3">
                  <CCol sm="6">
                    <div class="required form-group">
                      <label>
                        {{ $t("message.customer") }}
                      </label>
                      <CButton
                        type="button"
                        @click="showModal = true"
                        class="copy add-btn"
                      >
                        +
                      </CButton>
                      <multiselect
                        id="customer"
                        v-model="customer"
                        :options="customers"
                        :searchable="true"
                        @select="selectCustomer"
                        :close-on-select="true"
                        :placeholder="$t('message.select')"
                        :selectLabel="$t('message.pressSelect')"
                        label="name"
                        track-by="id"
                        required="required"
                      >
                        <span slot="noOptions">{{
                          $t("message.emptyList")
                        }}</span>
                        <span slot="noResult"
                          >{{ $t("message.noResult") }}
                        </span>
                      </multiselect>
                    </div>
                  </CCol>
                  <CCol sm="6">
                    <CInput
                      :label="$t('message.balance')"
                      id="balance"
                      v-model="balance"
                      disabled
                      class="mt-3"
                      :placeholder="$t('message.balance')"
                    />
                  </CCol>
                </CRow>
              </div>
              <div v-else-if="selectedTypeOfOrder === 2">
                <CRow>
                  <h4>{{ $t("message.purchaseOrderNote") }}</h4>
                </CRow>
                <CRow>
                  <CCol sm="6">
                    <label for="suppliers">
                      {{ $t("message.supplier") }}
                      <span class="star">*</span>
                    </label>
                    <multiselect
                      class="required"
                      v-model="selectedSupplier"
                      id="supplier_id"
                      :options="suppliers"
                      :searchable="true"
                      @select="onSuppliersChange($event)"
                      :close-on-select="true"
                      :placeholder="$t('message.select')"
                      :selectLabel="$t('message.pressSelect')"
                      label="name"
                      track-by="name"
                      required="required"
                    >
                      <span slot="noOptions">{{
                        $t("message.emptyList")
                      }}</span>
                      <span slot="noResult">{{ $t("message.noResult") }} </span>
                    </multiselect>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="6">
                    <div class="required form-group">
                      <label>
                        {{ $t("message.customer") }}
                      </label>
                      <CButton
                        type="button"
                        @click="showModal = true"
                        class="copy add-btn"
                      >
                        +
                      </CButton>
                      <multiselect
                        class="required mt-2"
                        id="customer_id"
                        v-model="customer"
                        :options="customers"
                        :searchable="true"
                        @select="onChange($event)"
                        :close-on-select="true"
                        :placeholder="$t('message.select')"
                        :selectLabel="$t('message.pressSelect')"
                        label="name"
                        track-by="id"
                        required="required"
                      >
                        <span slot="noOptions">{{
                          $t("message.emptyList")
                        }}</span>
                        <span slot="noResult"
                          >{{ $t("message.noResult") }}
                        </span>
                      </multiselect>
                    </div>
                  </CCol>
                </CRow>
              </div>
            <hr>
            <CRow class="mt-3">
              <CCol col="6">
                <h3 class="mb-3">{{$t('message.products')}}</h3>
                <div class="line"></div>
              </CCol>
            </CRow>
             <button class="btn btn-primary px-3 py-2" type="button"  @click="addProduct"> {{$t('message.addProductToOrder')}}</button>
             <CRow class="mt-2">
              <table class="table">
                <thead>
                  <tr>
                    <th width="14%">
                      {{ $t("message.category") }}<span class="star">*</span>
                    </th>
                    <th width="17%">
                      {{ $t("message.product") }}<span class="star">*</span>
                    </th>
                    <th width="14%" v-if="selectedTypeOfOrder === 1">
                      {{ $t("message.transferNumber")
                      }}<span class="star">*</span>
                    </th>
                    <th width="10%">
                      {{ $t("message.price") }}<span class="star">*</span>
                    </th>
                    <th width="16%">
                      {{ $t("message.quantity") }}<span class="star">*</span>
                    </th>
                    <th width="16%">{{ $t("message.total") }}</th>
                    <th width="5%">{{ $t("message.deleteProduct") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(product, index) in products"
                    :key="index"
                  >
                  <td width="23%">
                     <multiselect
                        class='required'
                        id='category_id'
                        v-model="product.selectedCategory"
                        :options='categories'
                        :searchable='true'
                        @select="onCategoriesChange($event, index)"
                       :close-on-select='true'
                       :placeholder="$t('message.select')"
                       :selectLabel="$t('message.pressSelect')"
                        label='name'
                        track-by='id'
                        required='required'
                      >
                        <span slot='noOptions'>{{$t('message.emptyList')}}</span>
                        <span slot='noResult'>{{$t('message.noResult')}} </span>
                      </multiselect></td>
                    <td width="27%">
                      <multiselect
                        class='required'
                        id='product_id'
                        v-model="product.product"
                        :options='product.productOptions'
                        :searchable='true'
                        @select="currentProduct($event, index)"
                       :close-on-select='true'
                       :placeholder="$t('message.select')"
                       :selectLabel="$t('message.pressSelect')"
                        label='name'
                        track-by='id'
                        required='required'
                      >
                        <span slot='noOptions'>{{$t('message.emptyList')}}</span>
                        <span slot='noResult'>{{$t('message.noResult')}} </span>
                      </multiselect>
                    </td>
                     <td v-if="selectedTypeOfOrder === 1">
                      <CInput
                        type='number'
                        id='transfer_n umber'
                        min='1'
                        disabled
                        class="required"
                        v-model='product.transferNumber'
                      />
                    </td>
                      <td>
                      <CInput v-if=" pricePermission === -1 && adminRole ==='0'"
                         type='text'
                        id='price'
                        @input="calcPrice($event,index)"
                         class="required"
                        v-model='product.price'
                        required
                        disabled
                        />
                         <CInput v-else
                         type='text'
                        id='price'
                        @input="calcPrice($event,index)"
                         class="required"
                        v-model='product.price'
                        required
                        />
                    </td>
                    <td>
                       <CInput
                        type='number'
                        id='quantity'
                        class="required"
                        min='1'
                        @input="calcTotal($event,index)"
                        v-model='product.quantity'
                        required
                      />
                    </td>
                    <td>
                       <CInput
                        type='number'
                        disabled
                        id='total'
                        min='0'
                        v-model='product.total'
                        required
                      />
                    </td>
                    <td>
                     <CButton
                        class="removeItem"
                        type="button"
                        size="sm"
                        color="danger"
                        @click="removeProduct(index)"
                      >
                      {{ $t('message.clear')}}
                      </CButton>
                    </td>
                  </tr>
                </tbody>
              </table>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CButton type='submit' size='sm' color='primary'>
            <CIcon name='cil-check-circle'/> {{$t('message.save')}}</CButton>
              &emsp;
            <CButton type="button" size="sm" color="btn btn-primary" @click="goBack">
            <CIcon name="cil-ban"/> {{ $t('message.back') }} </CButton>
          </CCardFooter>
          </form>
        </CCard>
      </CCol>
    </CRow>
    <Modal v-model="showModal">
      <NewCustomer />
    </Modal>
  </div>
</template>

<script>
import NewCustomer from '@/components/NewCustomer.vue'
import $ from 'jquery'
import swal from 'sweetalert'

export default {
  name: 'UpdateOrder',
  data: function () {
    return {
      showModal: false,
      order: {},
      products: [],
      sendProducts: [],
      productOptions: [],
      orderStatuses: [],
      customers: [],
      suppliers: [],
      selectedSupplier: '',
      selectedProduct: '',
      selectedCustomer: '',
      selectedTypeOfOrder: '',
      balance: '',
      customer: {},
      customerPhone: '',
      categories: [],
      selectedCategory: '',
      selectedOffice: {},
      pricePermission: '',
      adminRole: '',
      errors: []
    }
  },
  components: {
    NewCustomer
  },
  created () {
    const categories = []
    this.$http
      .get(`${this.$hostUrl}categories`)
      .then((response) => {
        $.each(response.data.data, function (key, value) {
          categories.push({ id: value.id, name: value.name })
        })
        this.categories = categories
      })
    const offices = []
    this.$http.get(`${this.$hostUrl}offices`).then((response) => {
      $.each(response.data.data, function (key, value) {
        if (value.id !== 2) {
          offices.push({ id: value.id, name: value.name })
        }
      })
      this.offices = offices
    })
    const productOptions = []
    this.$http
      .get(`${this.$hostUrl}products`)
      .then((response) => {
        $.each(response.data.data, function (key, value) {
          productOptions.push({ id: value.id, name: value.name })
        })
        this.productOptions = productOptions
      })
    const customers = []
    this.$http
      .get(`${this.$hostUrl}customers`)
      .then((response) => {
        $.each(response.data.data, function (key, value) {
          customers.push({
            id: value.id,
            name: value.name + ' - ' + value.phone,
            phone: value.phone,
            balance: value.balance
          })
        })
        this.customers = customers
      })
    const suppliers = []
    this.$http
      .get(`${this.$hostUrl}suppliers`)
      .then((response) => {
        $.each(response.data.data, function (key, value) {
          suppliers.push({ id: value.id, name: value.name })
        })

        this.suppliers = suppliers
      })
    const orderStatuses = []
    this.$http
      .get(`${this.$hostUrl}order-status`)
      .then((response) => {
        $.each(response.data.data, function (key, value) {
          orderStatuses.push({ id: value.id, name: value.name })
        })
        this.orderStatuses = orderStatuses
      })
    const current = this
    this.$http
      .get(`${this.$hostUrl}orders/${this.$route.params.id}`)
      .then((response) => {
        console.log('gi', response.data.data)
        this.order = response.data.data
        if (this.order.customer) {
          this.customer = this.order.customer
          this.customer.name = this.order.customer.name + ' - ' + this.order.customer.phone
          this.customerPhone = this.order.customer.phone
          this.balance = this.order.customer.balance
        }
        if (this.order.supplier) {
          this.selectedSupplier = {
            id: this.order.supplier.id,
            name: this.order.supplier.name
          }
        }
        if (this.order.office) {
          this.selectedOffice = {
            id: this.order.office.id,
            name: this.order.office.name
          }
        }
        this.selectedTypeOfOrder = this.order.order_type.id
        $.each(this.order.products, function (key, value) {
          current.products.push({
            product: {
              id: value.product.id,
              product_id: value.product.id,
              name: value.product.name,
              price: value.product.price
            },
            id: value.id,
            quantity: value.quantity,
            price: value.price,
            total: value.price * value.quantity,
            transferNumber: value.transfer_number,
            selectedCategory:
            {
              id: value.product.category.id,
              name: value.product.category.name
            },
            productOptions: current.productOptions
          })
        })
      })
    this.pricePermission = localStorage.permissions.search('ChangeSalesPrice')
    this.adminRole = localStorage.isAdmin
  },
  methods: {
    search () {
      console.log('this.customerPhone', this.customerPhone)
      const current = this
      current.balance = ''
      if (this.customerPhone.length === 10) {
        this.$http
          .get(`${this.$hostUrl}customers-search?phone=${this.customerPhone}`)
          .then((response) => {
            current.customer = response.data.data
            current.balance = current.customer.balance
          })
      }
    },
    selectCustomer (event) {
      const current = this
      current.name = event.name
      current.balance = event.balance
      console.log('event', event)
      current.customerPhone = event.phone
    },
    onOfficeChange () {
      this.customerPhone = ''
      this.balance = ''
      this.customer = {}
      this.selectedSupplier = {}
    },
    onSuppliersChange ($event) {
      this.customer = {}
      this.balance = ''
      this.selectedOffice = {}
      this.products = []
      this.products.push({
        product: {
          product_id: null,
          supplier_id: null,
          price: null
        },
        id: (this.products.length + 1),
        quantity: 1,
        price: null,
        total: 0,
        selectedCategory: '',
        transferNumber: ''
      })
    },
    onCategoriesChange ($event, index) {
      const productOptions = []
      this.products[index].productOptions = []
      this.products[index].product = {}
      this.products[index].category_id = ''
      this.products[index].price = ''
      this.products[index].total = ''
      this.products[index].transferNumber = ''
      this.products[index].quantity = 1
      if (this.selectedTypeOfOrder === 2) {
        this.$http
          .get(
          `${this.$hostUrl}products-filter?category_id=${$event.id}&supplier_id=${this.selectedSupplier.id}`
          )
          .then((response) => {
            $.each(response.data.data, function (key, value) {
              productOptions.push({ id: value.id, name: value.name, supplier_id: value.supplier_id, price: value.price })
            })
            this.products[index].productOptions = productOptions
          })
      } else {
        this.$http
          .get(
          `${this.$hostUrl}category/${$event.id}/products`
          )
          .then((response) => {
            $.each(response.data.data.category.products, function (key, value) {
              productOptions.push({ id: value.id, name: value.name, supplier_id: value.supplier_id, price: value.price })
            })
            this.products[index].productOptions = productOptions
          })
      }
    },
    onChange ($event) {
      this.selectedSupplier = {}
      this.selectedOffice = {}
      this.balance = event.balance
    },
    addProduct () {
      this.products.push({
        product: {
          product_id: null,
          supplier_id: null,
          price: null
        },
        productOptions: this.productOptions,
        id: (this.products.length + 1),
        quantity: 1,
        price: null,
        total: 0,
        selectedCategory: '',
        transferNumber: ''
      })
    },
    removeProduct (index) {
      this.products.splice(index, 1)
    },
    calcTotal ($event, index) {
      this.products[index].total =
      (parseFloat($event) * parseFloat(this.products[index].price)).toFixed(2)
    },
    calcPrice ($event, index) {
      this.products[index].total = parseFloat($event) * parseFloat(this.products[index].quantity)
    },
    currentProduct ($event, index) {
      this.supplier_id = $event.supplier_id
      const supplierId = $event.supplier_id
      if (supplierId === 6) {
        this.products[index].transferNumber = this.customer.zain_transfer_no
      } else if (supplierId === 12) {
        this.products[index].transferNumber = this.customer.mtn_transfer_no
      } else if (supplierId === 1) {
        this.products[index].transferNumber = this.customer.digitel_transfer_no
      } else if (supplierId === 4) {
        this.products[index].transferNumber = this.customer.momo_transfer_no
      } else if (supplierId === 9) {
        this.products[index].transferNumber = this.customer.mgurush_transfer_no
      }
      this.products[index].price = $event.price
      this.products[index].total = parseFloat($event.price) * parseFloat(this.products[index].quantity)
    },
    async updateOrder (e) {
      e.preventDefault()
      const formData = new FormData()
      formData.append('order_type_id', this.selectedTypeOfOrder)
      if (this.selectedTypeOfOrder === 1) {
        if (this.customer.id) {
          formData.append('customer_id', this.customer.id)
        } else if (this.selectedOffice.id) {
          formData.append('office_id', this.selectedOffice.id)
        } else {
          swal(this.$t('message.saleOrderNote'))
          return 0
        }
      } else {
        if (this.selectedSupplier.id) {
          formData.append('supplier_id', this.selectedSupplier.id)
        } else if (this.customer.id) {
          formData.append('customer_id', this.customer.id)
        } else {
          swal(this.$t('message.purchaseOrderNote'))
          return 0
        }
      }
      this.sendProducts = []
      for (let i = 0; i <= this.products.length - 1; i++) {
        this.sendProducts.push({ product_id: this.products[i].product.id, price: parseFloat(this.products[i].price), quantity: parseFloat(this.products[i].quantity), transfer_number: this.products[i].transferNumber ?? '', total: this.products[i].total })
      }
      formData.append('products', JSON.stringify(this.sendProducts))
      formData.append('_method', 'PUT')
      this.errors = await this.postRequest(formData, `${this.$hostUrl}orders/${this.$route.params.id}`, '/orders')
    },
    goBack () {
      this.productsOpened ? this.$router.go(-1) : this.$router.push({ path: '/orders' })
    }
  }
}

</script>

<style>
.order{
  background-color: #9daec5;
  color: #fff;
  padding: 15px;
  border-radius: 20%;
  font-size: 30px !important;
}
.add{
   background-color: #2a3647 !important;
  color: #fff !important;
  font-size: 15px !important;
}
input[type="radio"]{
font-size: 7px !important;
cursor: pointer;
}
input[type="radio"]:focus{
box-shadow: unset !important;
}
.orderstype{
  display: flex;
  gap:37px;
}
.orderstype .star{
margin-right: -33px;
}

</style>
